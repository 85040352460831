body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(35, 34, 36, 0.9);
  width: 100vw;
  height: 100vh;
  min-width: -webkit-fill-available;
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'OneDay';
  src: local('OneDay'), url(./fonts/ONEDAY.ttf) format('truetype');
}

.mapboxgl-canvas-container {
  outline: 'none',
}

/* .element::-webkit-scrollbar { width: '100px' } */